import React, { useState } from 'react';

const Carousel = () => {
  const slides = [
    { id: 1, content: 'Slide 1 Content' },
    { id: 2, content: 'Slide 2 Content' },
    { id: 3, content: 'Slide 3 Content' },
    { id: 4, content: 'Slide 4 Content' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative lg:w-[60%] w-full mx-auto overflow-hidden">
      <div
        className="flex transition-transform duration-500"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide) => (
          <div
            key={slide.id}
            className="min-w-full h-64 flex items-center justify-center bg-gray-200"
          >
            <p className="lg:text-[4rem] text-[2.7rem]">{slide.content}</p>
          </div>
        ))}
      </div>
      
      {/* Navigation buttons */}
      <div className="absolute bottom-[4rem] left-[1.5rem] transform -translate-x-1/2 flex flex-col gap-5">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`p-2 rounded-full ${
              currentIndex === index ? 'bg-blue-500' : 'bg-gray-400'
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
